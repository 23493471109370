import React from "react";
import Button from "../elements/button";
import classNames from "classnames"
import WPImage from "../elements/wp-image";
import HomeBanner from "./home-banner";

const PageBanner = ({data}) => {

    if (data.size && data.size === 'lg') {
        return <HomeBanner data={data}/>
    }
    return (
        <div className="section-container">
            <div className={classNames('page-banner', 'curve-bottom', 'gradient-' + data.bg_colour)}>
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-12">
                            <div className="breadcrumbs">
                                <ul>
                                    <li><a href="/" title="Go to Home Page">Home</a></li>
                                    <li>{data.title}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-sm-7 col-lg-6">
                            <div className="page-banner-content">
                                <h1 dangerouslySetInnerHTML={{__html: data.title}}/>
                                <h5 dangerouslySetInnerHTML={{__html: data.subtitle}}/>
                                {data.intro && <p dangerouslySetInnerHTML={{__html: data.text}} className="lead red"/>}
                                {data.buttons && data.buttons.map(button => (
                                    <Button data={button}/>
                                ))}
                            </div>
                        </div>

                        <div className="col col-sm-5 col-lg-6">
                            <div className="page-banner-image">
                                <WPImage img={data.image}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageBanner