import {Link} from "gatsby";
import React from "react";
import classNames from "classnames"

const Button = ({data}) => {

    return (
        <Link to={data.link} className={classNames('btn', 'btn-' + data.type)}
              dangerouslySetInnerHTML={{__html: data.text}}/>
    )
}

export default Button