import React from 'react'
import classNames from 'classnames'
import WPImage from "../elements/wp-image";

const IconBlocks = ({data}) => {

    return (
        <div className='section-container'>
            <div
                className={classNames(
                    'section',
                    data.section_background ? 'bg-' + data.section_background : null,
                    'section-icons',
                    data.background_wave ? 'bg-wave' : null,
                    data.background_wave && data.section_background ? 'bg-wave-' + data.section_background : null,
                    data.section_curves
                )}>
                <div className='container'>
                    <div className='row'>
                        <div className='col col-md-8 col-md-offset-2'>
                            <div className={classNames('title', data.section_background !== 'white' ? 'section-dark' : '')}>
                                <h3>{data.title}</h3>
                            </div>
                        </div>
                        <div className='col col-md-12'>
                            <div className='row row-xs-full row-equal'>
                                {data.icon_blocks.map(block => (
                                    <div className='col col-xs-6 col-sm-6'>
                                        <div className='box icon-block'>
                                            {block.icon && (
                                                <div className='icon-block-image'>
                                                    <WPImage img={block.icon}/>
                                                </div>
                                            )}
                                            <div className='icon-block-content'>
                                                <h5 dangerouslySetInnerHTML={{__html: block.title}}/>
                                                <div dangerouslySetInnerHTML={{__html: block.text}}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IconBlocks