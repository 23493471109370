import React from "react";
import Button from "../elements/button";
import classNames from "classnames"
import WPImage from "../elements/wp-image";
import QuoteForm from "../partials/quote-form";
import Usps from "../partials/usps";
import {graphql, useStaticQuery} from "gatsby";
import AprText from "../partials/apr-text";

const HomeBanner = ({data}) => {

    const query = useStaticQuery(graphql`
        query {
            wordpressAcfOptions {
                options { 
                    loan_title                    
                    feefo_image {
                        ...WordPressImage
                    }
                }             
            }               
        }
    `)

    return (
        <div className="section-container">
            <div
                className="section-dark gradient-blue-purple bg-wave bg-wave-blue page-banner page-banner-lg home-banner curve-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
                            <div className="home-banner-content">
                                <h1 className="h4">Pre-Approved Personal Loans</h1>
                                <p className="lead">Empowering Financial Well-Being</p>
                                <QuoteForm/>
                                <Usps/>
                                <AprText />
                                <a href="/how-it-works" className="btn btn-sm btn-border">How it Works</a>
                                <div className="section-footer">
                                    <WPImage img={query.wordpressAcfOptions.options.feefo_image}
                                             className="feefo-logo"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeBanner