import React from "react"
import Slider from "react-slick";
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from "../elements/image";
import WPImage from "../elements/wp-image";

const Faqs = ({data}) => {

    const query = useStaticQuery(graphql`
        query {
            allWordpressWpFaq(sort: {fields: wordpress_id, order: DESC}) {
                nodes {
                    post_title : title
                    acf {
                        answer
                        image {
                            ...WordPressImage
                        }
                    }
                }
            }
        }
    `)

    const faqs = data.show === 'selected' ? data.faqs : query.allWordpressWpFaq.nodes

    return (
        <div className="section section-faqs">
            <div className="container">
                <div className="row">
                    <div className="col col-md-12">
                        {data.title && (
                            <div className="title">
                                <h3 dangerouslySetInnerHTML={{__html: data.title}}/>
                            </div>
                        )}
                        <Slider className="row row-equal" {...{
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            dots: true,
                            arrows: false,
                            responsive: [
                                {
                                    breakpoint: 991,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3
                                    }
                                },
                                {
                                    breakpoint: 767,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2
                                    }
                                },
                                {
                                    breakpoint: 479,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        }}>
                            {faqs && faqs.map(faq => (
                                <div className="col col-sm-3">
                                    <div className="faq">
                                        <div className="faq-image">
                                            <WPImage img={faq.acf.image} />
                                        </div>
                                        <div className="faq-content">
                                            <h6 dangerouslySetInnerHTML={{__html: faq.post_title}}/>
                                            <div dangerouslySetInnerHTML={{__html: faq.answer}}/>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="section-footer">
                            <Link to="/faqs" className="btn btn-primary">View All FAQs</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faqs