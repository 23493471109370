import React from "react"
import Img from "../elements/image"
import classNames from "classnames"
import WPImage from "../elements/wp-image";

const Steps = ({data}) => {

    console.log(data)

    return (
        <div className="section-container">
            <div
                className={classNames(
                    'section',
                    data.section_background ? 'bg-' + data.section_background : null,
                    'section-steps',
                    data.background_wave ? 'bg-wave' : null,
                    data.background_wave && data.section_background ? 'bg-wave-' + data.section_background : null,
                    data.section_curves,
                    data.section_background !== 'white' ? 'section-dark' : ''
                )}>
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-12">
                            {data.title && (
                                <div className="title">
                                    <h3 dangerouslySetInnerHTML={{__html: data.title}}/>
                                </div>
                            )}
                            {data.steps && (
                                <div className="steps">
                                    {data.steps.map((object, index) => {
                                        const isOdd = index % 2 == 1
                                        return (
                                            <div className="row row-v-centre step">
                                                <div className={classNames(
                                                    'col',
                                                    'col-sm-5',
                                                    'col-step-image',
                                                    {'col-sm-push-7': isOdd}
                                                )}>
                                                    <div className="step-image">
                                                        <WPImage img={object.image}/>
                                                    </div>
                                                </div>
                                                <div className="col col-sm-2 col-step-number">
                                                    <div className="step-number">
                                                        {object.ticked ? (
                                                            <i className="fas fa-check"></i>
                                                        ) : (
                                                            <span dangerouslySetInnerHTML={{__html: index + 1}}/>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={classNames(
                                                    'col',
                                                    'col-sm-5',
                                                    'col-step-content',
                                                    {'col-sm-pull-7': isOdd}
                                                )}>
                                                    <div className="step-content">
                                                        <h5 dangerouslySetInnerHTML={{__html: object.title}}/>
                                                        <p dangerouslySetInnerHTML={{__html: object.text}}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Steps