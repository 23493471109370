import React from "react"
import $ from 'jquery';
import WPImage from "../elements/wp-image";

class CategoryFaqs extends React.Component {

    componentDidMount() {
        $(this.panelGroup).find('.panel-title').click(function (e) {
            e.preventDefault()
            $(this).toggleClass('open');
            var parent = $(this).parents('.accordion');
            parent.find('.panel-body').slideToggle(400);
        });
    }

    render() {
        return (
            <div className="section section-faq" id="faqsection">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-4">
                            <div className="box box-icon">
                                <div className="box-image">
                                    <WPImage img={this.props.data.image} />
                                </div>
                                <div className="box-content">
                                    <h5 dangerouslySetInnerHTML={{__html: this.props.data.title}}/>
                                    <div dangerouslySetInnerHTML={{__html: this.props.data.text}}/>
                                </div>
                            </div>
                        </div>
                        {this.props.data.faqs && (
                            <div className="col col-sm-8 col-lg-7 col-lg-offset-1">
                                <div className="panel-group accordions" ref={group => this.panelGroup = group}>
                                    {this.props.data.faqs.map(object => (
                                        <div className="panel panel-default accordion">
                                            <div className="panel-heading">
                                                <h4 className="panel-title">
                                                    <a href="" role="button" data-toggle="collapse"
                                                       dangerouslySetInnerHTML={{__html: object.post_title}}/>
                                                </h4>
                                            </div>
                                            <div className="panel-collapse">
                                                <div className="panel-body"
                                                     dangerouslySetInnerHTML={{__html: object.acf.answer}}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CategoryFaqs