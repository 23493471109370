import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Usps from "../../components/partials/usps"
import QuoteForm from "../../components/partials/quote-form"

const Quote = ({data}) => {

    const query = useStaticQuery(graphql`
        query {
            wordpressAcfOptions {
                options { 
                    loan_title
                    personalised_apr_text
                }             
            }    
        }
    `)

    return (
        <div className="section section-quote bg-wave">
            <div className="container">
                <div className="row">
                    <div className="col col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2">
                        <div className="section-dark gradient-blue-purple box box-quote">
                            <h3 dangerouslySetInnerHTML={{
                                __html: data.title ? data.title : query.wordpressAcfOptions.options.loan_title
                            }}/>
                            <Usps/>
                            <QuoteForm/>
                            <div dangerouslySetInnerHTML={{
                                __html: query.wordpressAcfOptions.options.personalised_apr_text
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quote