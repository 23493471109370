import React from "react"
import classNames from "classnames"
import WPImage from "../elements/wp-image";

const ContentImage = ({data}) => {

    const isRight = data.image_position === 'right'

    return (
        <div className="section section-content-image">
            <div className="container">
                <div className="row">
                    <div className={classNames('col', 'col-sm-6', {'col-sm-push-6': isRight})}>
                        <div className="section-content-image-image">
                            <WPImage img={data.image} />
                        </div>
                    </div>
                    <div className={classNames('col col-sm-6', {
                        'col-sm-pull-6 col-lg-5': isRight,
                        'col col-sm-6 col-lg-5 col-lg-push-1': !isRight
                    })}>
                        <div className="section-content-image-content entry-content"
                             dangerouslySetInnerHTML={{__html: data.content}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentImage