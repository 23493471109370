import React from "react"
import classNames from "classnames"

const Content = ({data}) => (
    <div className={classNames('section', 'section-content', 'entry-content', 'text-' + data.align)}>
        <div className="container">
            <div className="row">
                <div className="col col-md-8 col-md-offset-2">
                    <div className="compliance">
                        <div dangerouslySetInnerHTML={{__html: data.content}}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default Content