import React from "react"
import Slider from "react-slick";
import {graphql, useStaticQuery} from "gatsby"
import WPImage from "../elements/wp-image";

const Testimonials = ({data}) => {

    const query = useStaticQuery(graphql`
        query {
            allWordpressWpTestimonial(sort: {fields: wordpress_id, order: DESC}) {
                nodes {
                    post_title : title
                    acf {                        
                        image {
                            ...WordPressImage
                        }
                        location
                        quote
                    }
                }
            }
            wordpressAcfOptions {
                options { 
                    feefo_image {
                        ...WordPressImage
                    }
                }             
            }                
        }
    `)

    const testimonials = data.show === 'selected' ? data.testimonials : query.allWordpressWpTestimonial.nodes

    return (
        <div className="section-container">
            <div className="section bg-blue section-testimonials curve-top curve-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col col-sm-12">
                            <div className="title section-dark">
                                <h3 dangerouslySetInnerHTML={{__html: data.title}}/>
                            </div>
                            <Slider className="row row-equal testimonials" {...{
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                dots: true,
                                arrows: false,
                                responsive: [
                                    {
                                        breakpoint: 991,
                                        settings: {
                                            slidesToShow: 2,
                                            slidesToScroll: 2
                                        }
                                    },
                                    {
                                        breakpoint: 479,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1
                                        }
                                    }
                                ]
                            }}>
                                {testimonials && testimonials.map(object => (
                                    <div className="col col-sm-4">
                                        <div className="box testimonial">
                                            <div className="testimonial-image">
                                                <WPImage img={object.acf.image} />
                                            </div>
                                            <div className="testimonial-content">
                                                <h6 className="author"
                                                    dangerouslySetInnerHTML={{__html: object.post_title}}/>
                                                <p className="location"
                                                   dangerouslySetInnerHTML={{__html: object.acf.location}}/>
                                                <p className="quote"
                                                   dangerouslySetInnerHTML={{__html: object.acf.quote}}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="section-footer">
                                <WPImage img={query.wordpressAcfOptions.options.feefo_image} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonials